import { ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { useStore } from "vuex";

export function useUnsavedModal(sections) {
  const route = useRoute();
  const store = useStore();

  const isModalOpen = ref(false);
  const nextRoute = ref(null);
  const unsavedSections = ref([]);
  const isInvalidSectionsPresent = ref(false);

  const innerSectionsValidity = ref({});

  innerSectionsValidity.value = sections.reduce((o, key) => Object.assign(o, { [key]: true }), {});

  const cancelModal = () => {
    isModalOpen.value = false;
  }

  const moveToNextRoute = () => {
    nextRoute.value();
    cancelModal();
  }

  const checkForUnsavedSections = (sectionData, value) => {
    if(value) {
      unsavedSections.value.push(sectionData);
    } else {
      unsavedSections.value = unsavedSections.value.filter(section => section.title !== sectionData.title);
    }
  };

  const setSectionsValidity = (section, value) => {
    innerSectionsValidity.value[section] = value;
  };

  const checkSectionValid = () => !Object.values(innerSectionsValidity.value).some(item => !item);

  const saveUnsavedSections = async (preparedData, isEditProductMode) => {
    if(isEditProductMode) {
      await store.dispatch('product/updateProduct', { id: +route.params.id, data: preparedData }).finally(() => {
        moveToNextRoute();
      })
    }
  }

  onBeforeRouteLeave((to, from, next) => {
    if (unsavedSections.value.length > 0) {
      isModalOpen.value = true;
      nextRoute.value = next;
      isInvalidSectionsPresent.value = checkSectionValid();
    } else {
      next();
    }
  });

  return {
    checkForUnsavedSections,
    setSectionsValidity,
    saveUnsavedSections,
    isInvalidSectionsPresent,
    unsavedSections,
    isModalOpen,
    moveToNextRoute,
    cancelModal
  }
}
