<template>
  <div>
    <TransitionRoot as="template" :show="isOpen">
      <Dialog :open="isOpen" as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('toggleModal')">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-universeBlue bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden
            shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:text-left sm:mt-0 sm:ml-0">
                    <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                      <slot name="title">
                      </slot>
                    </DialogTitle>
                    <div class="mt-3 mb-3 text-gray-600 text-sm">
                      <slot name="content">
                      </slot>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <div v-if="isInvalidSectionsPresent" class="mt-2 mb-2 sm:mb-0 sm:mt-0 sm:ml-3">
                  <button type="button" class="w-full inline-block btn btn-sm btn-highlight" @click="$emit('saveAndContinue')">
                    Save
                  </button>
                </div>
                <div class="flex flex-col sm:flex-row sm:flex-row-reverse gap-3">
                  <button
                    type="button"
                    class="w-full inline-block btn btn-sm"
                    :class="isInvalidSectionsPresent ? 'btn-white' : 'btn-red'"
                    @click="$emit('continue')">
                    <slot name="buttonName">
                    </slot>
                  </button>
                </div>
                <div class="mt-2 sm:mt-0 sm:mr-auto">
                  <button ref="cancelButtonRef" type="button" class="w-full inline-block btn btn-sm btn-transparent hover:bg-gray-100"
                          @click="$emit('toggleModal')">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";

export default {
  name: "AppUnsavedChangesModal",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isInvalidSectionsPresent: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggleModal', 'continue', 'saveAndContinue']
};
</script>
